import { faEnvelope, faMagnifyingGlassChart, faTable, faUnlock, faComments, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect, useReducer, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function AdminHome() {
    return <main>
        <div className="heading-wrapper">
            <h1>Admin</h1>
			<div className="heading-wrapper-actions">
				<Link to={`feedback`} className="secondary-button w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faComments} /></span>
					Feedback
				</Link>
				<Link to={`free-access`} className="secondary-button w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faUnlock} /></span>
					Free access
				</Link>
				<Link to={`verification-link`} className="secondary-button w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faEnvelope} /></span>
					Verification link
				</Link>
				<Link to={`algorithm`} className="secondary-button w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faMagnifyingGlassChart} /></span>
					Algorithm
				</Link>
				{/* <Link to={`/algorithm-data`} className="secondary-button w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faTable} /></span>
					Algorithm data
				</Link> */}
				<Link to={`add-doctor`} className="w-button fa-icon-button">
                    <span className="fa-wrapper"><FontAwesomeIcon icon={faUserPlus} /></span>
					Add doctor
				</Link>
			</div>
        </div>
        <div className="rounded-container">

        </div>
    </main>

}