import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/Loading';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ErrorBox, ErrorText } from '../../components/Errors';
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { DEFAULT_TOAST_SETTINGS } from '../../utils';
import { toast } from 'react-toastify';



type ResetPasswordFormValues = {
    email: string,
    password: string,
    confirm_password: string
};


export default function Invitation() {
    const params = useParams<{code: string}>();
	const axiosContext = useContext(AxiosContext)!;
	const [isVerified, setIsVerified] = useState<boolean | null>(null);
    // const [firstName, setFirstName] = useState<string>("");
	const [validationError, setValidationError] = useState<string | null>(null);
	const [setupError, setSetupError] = useState<string | null>(null);
    const navigate = useNavigate();

    const validateToken = async () => {
        try {
            const response = await axiosContext.publicAxios.get<{first_name: string}>(`/invitations/${params.code}/verify`);
            // setFirstName(response.data.first_name);
            setIsVerified(true);
        } catch (e: any) {
            setIsVerified(false);
            axiosContext.catchAxios(e, setValidationError);
        }
    };

    useEffect(() => {
        validateToken();
    }, []);


    const onSubmit = async (values: ResetPasswordFormValues, { setSubmitting }: FormikHelpers<ResetPasswordFormValues>) => {
        setSetupError(null);
        try {
            const response = await axiosContext.publicAxios.post(`/invitations/${params.code}/password`, values);
            navigate("/accounts/login");
            toast("Password updated. You may now login.", DEFAULT_TOAST_SETTINGS);
        } catch (e: any) {
            axiosContext.catchAxios(e, setSetupError);
        }
    };

    const initialValues: ResetPasswordFormValues = {
        email: "",
        password: "",
        confirm_password: ""
    };

    let content;
    if (isVerified === null) {
        content = <Loading text="Verifying..." />;
    } else if (validationError) {
        content = <ErrorBox>{validationError}</ErrorBox>;
    } else if (isVerified === false) {
        content = <ErrorBox>Couldn't verify account.</ErrorBox>;
    } else if (isVerified === true) {
        content = <>
            <div className="heading-wrapper">
                <h1>Account setup</h1>
            </div>
            <div className="rounded-container">
                <p>
                    Welcome to SherpahConnect, to get started please input the email address from which you received this invitation link, then set your account password:
                </p>
                <Formik 
                    initialValues={initialValues}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object({
                        email: Yup.string().label("Email").required(),
                        password: Yup.string().label("Password").required(),
                        confirm_password: Yup.string().label("Confirm password").required().oneOf([Yup.ref("password")], "Passwords must match.")
                    })}>
                        {({isSubmitting}) => (
                        <Form className="flex-col-gap-8">
                            { setupError ? <ErrorText>{setupError}</ErrorText> : null }
                            <div className="form-field">
                                <label>Email address</label>
                                <ErrorMessage component={ErrorText} name="email" />
                                <Field type="email" name="email" />
                            </div>
                            <div className="form-field">
                                <label>Password</label>
                                <ErrorMessage component={ErrorText} name="password" />
                                <Field type="password" name="password" />
                            </div>
                            <div className="form-field">
                                <label>Confirm password</label>
                                <ErrorMessage component={ErrorText} name="confirm_password" />
                                <Field type="password" name="confirm_password" />
                            </div>
                            <div className="actions">
                                { isSubmitting ?
                                <button type="submit" disabled>Saving...</button> :
                                <button type="submit">Save password</button> }
                            </div>
                        </Form>
                        )}
                </Formik>
            </div>
        </>;
    }

    return <main className="narrow">
        {content}
    </main>;

    // return <main>
    //     {content}
    // </main>;

}
