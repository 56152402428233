import { useContext, useEffect, useState } from "react";
import { Link, Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import { AuthContext, AuthSource } from "./contexts/AuthContext";
import { User } from "./types";
import { SharedProvider } from "./contexts/SharedContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressCard, faChartLine, faGear, faRightFromBracket, faUserNurse } from "@fortawesome/free-solid-svg-icons";
import { PatientProvider } from "./contexts/PatientContext";

function Unauthenticated() {
	const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();

    useEffect(() => {
        if (authContext.authState.user) navigate("/");
    }, [authContext.authState.user]);

    return <Outlet />;
}


function Navigation() {
	const authContext = useContext(AuthContext)!;
    const user = authContext.authState.user;
    const navigate = useNavigate();
    // TODO: indicate current page in nav menu
    // const match = useMatch("patients/*");

    return <nav>
        <div className="top-bar">
        <Link to="/" className="logo-block">
            <img src="/logo-rounded.png" loading="lazy" alt="" className="logo" />
        </Link>
        </div>
        { user ? 
        <div className="menu-items">
            <div className="menu-nav-items">
                { user.is_doctor ? <>
                    <Link to="/patients" className="menu-item">
                        <div className="nav-icon"><FontAwesomeIcon icon={faAddressCard} /></div>
                        <div className="nav-text">Patients</div>
                    </Link>
                    <Link to="/operators" className="menu-item">
                        <div className="nav-icon"><FontAwesomeIcon icon={faUserNurse} /></div>
                        <div className="nav-text">Operators</div>
                    </Link>
                </> : null }

                { user.is_operator ? <>
                    <Link to="/patients" className="menu-item">
                        <div className="nav-icon"><FontAwesomeIcon icon={faAddressCard} /></div>
                        <div className="nav-text">Patients</div>
                    </Link>
                </> : null }

                { user.is_patient ? <>
                    <Link to={`/patients/${user.id}`} className="menu-item">
                        <div className="nav-icon"><FontAwesomeIcon icon={faAddressCard} /></div>
                        <div className="nav-text">Profile</div>
                    </Link>
                    <Link to={`/patients/${user.id}/report`} className="menu-item">
                        <div className="nav-icon"><FontAwesomeIcon icon={faChartLine} /></div>
                        <div className="nav-text">Report</div>
                    </Link>
                </> : null }

            </div>
            <div className="menu-logout-wrapper">
                { user.is_operator || user.is_doctor ? <>
                    <Link to="/settings" className="menu-item">
                        <div className="nav-icon"><FontAwesomeIcon icon={faGear} /></div>
                        <div className="nav-text">Settings</div>
                    </Link>
                </> : null }
                <div className="menu-item" onClick={() => {
                    authContext.logout();
                    navigate("/accounts/login");
                }}>
                    <div className="nav-icon"><FontAwesomeIcon icon={faRightFromBracket} /></div>
                    <div className="nav-text">Log out</div>
                </div>
            </div>
        </div> : null }
    </nav>;
}


const AUTH_MESSAGE = <main><p>Authenticating...</p></main>;


function Authenticated() {
	const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();

    useEffect(() => {
        const access = localStorage.getItem("access");
        const refresh = localStorage.getItem("refresh");
        const userJson = localStorage.getItem("user");
        if (access && refresh && userJson && userJson !== "undefined") {
            const user = JSON.parse(userJson!) as User;
            authContext.setAuthState({
                authenticated: true,
                user: user,
                authSource: AuthSource.django
            });
            authContext.accessToken.current = access;
            authContext.refreshToken.current = refresh;
        } else if (!authContext.authState.cognitoSession) {
            authContext.logout();
            navigate("/accounts/login");
        }
    }, []);

    if (authContext.authState.user) {
        return <SharedProvider>
            <div className="container">
                <Navigation />
                <Outlet />
            </div>
        </SharedProvider>;
    } else {
        return AUTH_MESSAGE;
    }
}


function DoctorOnly() {
	const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();
    const [isAuthorised, setIsAuthorised] = useState<boolean>(false);

    useEffect(() => {
        const user = authContext.authState.user;
        if (user && user.is_doctor) {
            setIsAuthorised(true);
        } else {
            navigate("/");
        }
    }, [authContext.authState.user]);

    return isAuthorised ? <Outlet /> : AUTH_MESSAGE;
}


function DoctorAndOperators() {
	const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();
    const [isAuthorised, setIsAuthorised] = useState<boolean>(false);

    useEffect(() => {
        const user = authContext.authState.user;
        if (user && (user.is_doctor || user.is_operator)) {
            setIsAuthorised(true);
        } else {
            navigate("/");
        }
    }, [authContext.authState.user]);

    return isAuthorised ? <Outlet /> : AUTH_MESSAGE;
}


function Patient() {
	const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();
    const params = useParams<{patientId: string}>();
    const [isAuthorised, setIsAuthorised] = useState<boolean>(false);

    useEffect(() => {
        const user = authContext.authState.user;
        if (user) {
            setIsAuthorised(true);
        } else {
            navigate("/");
        }
    }, [authContext.authState.user]);

    return isAuthorised ? <PatientProvider patientId={params.patientId!}>
        <Outlet />
    </PatientProvider> : AUTH_MESSAGE;
}


function Admin() {
	const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();
    const [isAuthorised, setIsAuthorised] = useState<boolean>(false);

    useEffect(() => {
        const user = authContext.authState.user;
        if (user && user.is_superuser) {
            setIsAuthorised(true);
        } else {
            navigate("/");
        }
    }, [authContext.authState.user]);

    return isAuthorised ? <Outlet /> : AUTH_MESSAGE;
}




export default {
    Unauthenticated,
    Authenticated,
    DoctorOnly,
    DoctorAndOperators,
    Patient,
    Admin
};