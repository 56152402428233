import { useContext, useState, useEffect } from 'react';
import { AxiosContext } from '../../contexts/AxiosContext';
import { ErrorText } from '../../components/Errors';
import "../doctors/doctor.scss";

interface FeedbackData {
    id: number;
    created_at: string;
    comment: string;
    user: {
        id: number;
        email: string;
        full_name: string;
    };
    enjoying_app: boolean;
}

export default function Feedback() {
    const [error, setError] = useState<string | null>(null);
    const [feedback, setFeedback] = useState<FeedbackData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
    const [sortColumn, setSortColumn] = useState<"created_at" | "comment" | "full_name" | "email" | "enjoying_app">("created_at");
    const [searchQuery, setSearchQuery] = useState<string>("");
    const { sherpahGet, catchAxios } = useContext(AxiosContext)!;

    useEffect(() => {
        const fetchFeedback = async () => {
            setError(null);
            setLoading(true);
            try {
                const response = await sherpahGet<FeedbackData[]>("/patients/feedback");
                setFeedback(response.data);
            } catch (e: any) {
                catchAxios(e, setError);
            } finally {
                setLoading(false);
            }
        };

        fetchFeedback();
    }, [sherpahGet, catchAxios]);

    const handleColumnClick = (column: "created_at" | "comment" | "full_name" | "email" | "enjoying_app") => {
        if (column === sortColumn) {
            setSortOrder((sortOrder) => sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortColumn(column);
            setSortOrder("asc");
        }
    };

    let sortedFeedback = [...feedback];
    if (sortColumn === "created_at") {
        sortedFeedback.sort((a, b) => {
            const aValue = new Date(a.created_at).getTime();
            const bValue = new Date(b.created_at).getTime();
            return sortOrder === "asc" ? aValue - bValue : bValue - aValue;
        });
    } else if (sortColumn === "comment") {
        sortedFeedback.sort((a, b) => {
            const aValue = a.comment.toLowerCase();
            const bValue = b.comment.toLowerCase();
            return sortOrder === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
    } else if (sortColumn === "full_name") {
        sortedFeedback.sort((a, b) => {
            const aValue = a.user.full_name.toLowerCase();
            const bValue = b.user.full_name.toLowerCase();
            return sortOrder === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
    } else if (sortColumn === "email") {
        sortedFeedback.sort((a, b) => {
            const aValue = a.user.email.toLowerCase();
            const bValue = b.user.email.toLowerCase();
            return sortOrder === "asc" ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        });
    } else if (sortColumn === "enjoying_app") {
        sortedFeedback.sort((a, b) => {
            return sortOrder === "asc" ? Number(a.enjoying_app) - Number(b.enjoying_app) : Number(b.enjoying_app) - Number(a.enjoying_app);
        });
    }

    let sortChevron = (sortOrder === "asc") ? "▼" : "▲";

    return (
        <main>
            <div className="heading-wrapper">
                <h1>Feedback</h1>
            </div>
            <div className="rounded-container">
                {error ? <ErrorText>{error}</ErrorText> : null}
                <div className='flex-col-gap-16'>
                    <div className="search-wrapper">
                        <img src="/search.png" />
                        <input type="text" placeholder="Search feedback..." onChange={event => setSearchQuery(event.target.value)} />
                    </div>
                    <div className="list-container tall-list">
                        <div className="list-columns feedback-list-grid">
                            <div className="list-column" onClick={() => handleColumnClick("full_name")}>
                                FULL NAME {sortColumn === "full_name" ? sortChevron : null}
                            </div>
                            <div className="list-column" onClick={() => handleColumnClick("email")}>
                                EMAIL {sortColumn === "email" ? sortChevron : null}
                            </div>
                            <div className="list-column" onClick={() => handleColumnClick("enjoying_app")}>
                                ENJOYING APP {sortColumn === "enjoying_app" ? sortChevron : null}
                            </div>
                            <div className="list-column" onClick={() => handleColumnClick("comment")}>
                                COMMENT {sortColumn === "comment" ? sortChevron : null}
                            </div>
                            <div className="list-column" onClick={() => handleColumnClick("created_at")}>
                                CREATED AT {sortColumn === "created_at" ? sortChevron : null}
                            </div>
                        </div>
                        {sortedFeedback
                            .filter(fb => fb.comment.toLowerCase().includes(searchQuery.toLowerCase()) || fb.user.full_name.toLowerCase().includes(searchQuery.toLowerCase()) || fb.user.email.toLowerCase().includes(searchQuery.toLowerCase()))
                            .map((fb, index) => (
                                <div key={`${fb.id}-${index}`} className="list-item feedback-list-grid">
                                    <div>{fb.user.full_name}</div>
                                    <div>{fb.user.email}</div>
                                    <div style={{ color: fb.enjoying_app ? 'green' : 'red' }}>
                                        {fb.enjoying_app ? 'yes' : 'no'}
                                    </div>
                                    <div>{fb.comment}</div>
                                    <div>{fb.created_at}</div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </main>
    );
}