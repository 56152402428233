import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { AxiosContext } from '../../contexts/AxiosContext';
import { Doctor } from '../../types';
import "./doctor.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTrashArrowUp } from '@fortawesome/free-solid-svg-icons';
import { ErrorText } from '../../components/Errors';
import Loading from '../../components/Loading';
import { formatDatetime } from '../../utils';
import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';


export default function Settings() {
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [doctor, setDoctor] = useState<Doctor | null>(null);
    const { sherpahGet, sherpahPut, catchAxios } = useContext(AxiosContext)!;
    const { user } = useContext(AuthContext)!.authState;

    const getDoctor = async () => {
        try {
            let response;
            if (user?.is_operator) {
                response = await sherpahGet<{doctor: Doctor}>(`/operators/${user.id}/patients`);
            } else {
                response = await sherpahGet<{doctor: Doctor}>(`/doctors/${user!.id}/patients`);
            }
            setDoctor(response.data.doctor);
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    };

    const setDeidentifyPatients = async (isOn: boolean) => {
        // setLoading(true);
        try {
            const response = await sherpahPut(`/doctors/${user!.id}`, {deidentify_patients: isOn});
        } catch (e: any) {
            catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        getDoctor();
    }, []);

    let content;
    if (error != null) {
        content = <main><ErrorText>{error}</ErrorText></main>;
    } else if (loading) {
        content = <main><Loading /></main>;
    } else {
        content = <main>
            <h1>Settings</h1>
            <div className="rounded-container">
                <h3 style={{ fontWeight: "normal" }}>
                    Your linking code is: <code style={{ fontWeight: 600 }}>{doctor!.linking_code}</code>
                </h3>
                <p>Give this code to any new patients so they can share their profile with you.</p>
                <hr style={{ margin: "16px 0" }}/>
                <div>
                    <input id="deidentify" type="checkbox" onChange={e => setDeidentifyPatients(e.target.checked)} defaultChecked={doctor!.deidentify_patients} />
                    <label htmlFor="deidentify">Deidentify patients</label>
                    <p>
                        Turn this setting on to hide all the names of your patients on SherpahConnect.
                        This setting is useful for showing other clinicians a patient list without revealing identities.
                    </p>
                </div>
                <hr style={{ margin: "16px 0" }}/>
                <Link to="/deleted-items" className="w-button secondary-button fa-icon-button">
                    <FontAwesomeIcon icon={faTrash} />
                    View deleted items
                </Link>
            </div>
        </main>;
    }
    
    return content;
}
