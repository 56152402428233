import React, {useContext, FormEventHandler, FormEvent, useState} from "react";
import { Routes, Route, Outlet, Link, useNavigate } from "react-router-dom";
import { AxiosContext } from "../../contexts/AxiosContext";
import { AuthContext, AuthSource } from "../../contexts/AuthContext";

import './login.scss';
import { User } from "../../types";
import { ErrorText } from "../../components/Errors";
import { UnauthContainer } from "./UnauthContainer";


export type SherpahToken = {
    access: string,
    refresh: string,
    is_verified?: boolean,
    user: User
};


export default function Login() {
	const axiosContext = useContext(AxiosContext)!;
    const authContext = useContext(AuthContext)!;
    const navigate = useNavigate();
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

    const handleLogin = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await axiosContext.publicAxios.post<SherpahToken>("api/token/", {
                email,
                password
            });

            const {access, refresh, user} = response.data;
    
            authContext.setAuthState({
                authenticated: true,
                user: user,
                authSource: AuthSource.django
            });
            authContext.accessToken.current = access;
            authContext.refreshToken.current = refresh;
    
            localStorage.setItem("access", access);
            localStorage.setItem("refresh", refresh);
            localStorage.setItem("user", JSON.stringify(user));
    
            if (user.is_doctor || user.is_operator) {
                navigate("/patients");
            } else if (user.is_patient) {
                navigate("/patients/" + user.id);
            } else if (user.is_superuser) {
                navigate("/admin");
            }
        } catch (e: any) {
            axiosContext.catchAxios(e, setError);
        } finally {
            setLoading(false);
        }
    }

    return <UnauthContainer>
        { error ? <ErrorText>{error}</ErrorText> : null }
        <form className="login-form" onSubmit={handleLogin}>
            <div className="form-question">
                <label>Email address</label>
                <input type="text" name="email" onChange={e => setEmail(e.currentTarget.value)} />
            </div>
            <div className="form-question">
                <label>Password</label>
                <input type="password" name="password" onChange={e => setPassword(e.currentTarget.value)} />
            </div>
            <input type="hidden" name="next" value="{{next}}" />

            { loading ?
                <button className="w-button" disabled>Logging in...</button> :
                <button className="w-button" type="submit">Login</button>
            }

            <Link to="/accounts/reset-password" className="link" style={{textAlign: "center"}}>Forgot password?</Link>
        </form>
    </UnauthContainer>;
}