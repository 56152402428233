import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip as TooltipChartJS,
    ChartData,
    BarElement,
    LineController,
    BarController,
    TimeScale,
    ChartOptions,
    ChartDataset,
    ScaleOptionsByType,
    Plugin
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BestFit, TrendMetricType, Trends } from './reportTypes';
import { DateTime } from 'luxon';
import { formatDate } from '../../../utils';


ChartJS.register(
    CategoryScale,
    LinearScale,
    TimeScale,
    PointElement,
    LineElement,
    Legend,
    BarElement,
    LineController,
    BarController,
    TooltipChartJS,
    zoomPlugin,
    ChartDataLabels
);

type TrendsGraphProps = {
    trends: Trends,
    metrics: Array<TrendMetricType>,
    dateRange: [Date, Date],
    showTrendlines: boolean,
    onHover?: (dateStr: string) => void
};

function trendDataset(axisId: string, label: string, data: number[], bestFit: BestFit, colour: string, showTrendlines: boolean): ChartDataset<"line", number[] | {x: DateTime, y: number}[]>[] {
    let datasets: ChartDataset<"line", number[] | {x: DateTime, y: number}[] >[] = [{
        label: label,
        data: data,
        borderColor: colour,
        pointBackgroundColor: colour,
        pointRadius: 5,
        borderJoinStyle: "round" as const,
        borderWidth: 2.5,
        yAxisID: `y_${axisId}`
    }];

    if (showTrendlines) {
        const date0 = DateTime.fromISO(bestFit[0][0]);
        const date1 = DateTime.fromISO(bestFit[0][1]);
        datasets.push({
            data: [
                {x: date0, y: bestFit[1][0]},
                {x: date1, y: bestFit[1][1]}
            ],
            yAxisID: `y_${axisId}`,
            borderColor: colour,
            borderDash: [8,8],
            borderWidth: 2.5,
            pointRadius: 0
        })
    }
    
    return datasets;
}

function dassDataset(label: string, colour: string, scores: number[], timestamps: string[]): ChartDataset<"line", { x: DateTime; y: number; }[]> {
    let data = [];
    for (let i = 0; i < scores.length; i++) {
        let timestamp = DateTime.fromISO(timestamps[i]);
        data.push({x: timestamp, y: scores[i]});
    }
    return {
        data: data,
        borderColor: colour,
        pointBackgroundColor: colour,
        pointRadius: 5,
        borderJoinStyle: "round" as const,
        borderWidth: 2.5,
        borderDash: undefined,
        yAxisID: `y_dass`
    };   
}

export function TrendsGraph(props: TrendsGraphProps) {
    if (props.metrics.length == 0) {
        return <i className="empty-message">Please select a metric below</i>;
    }

    const scoresScale = {min: 0, max: 10};
    const dassScale = {min: 0, max: 21};

    let datasets: ChartDataset<"line" | "bar", number[] | {x: DateTime, y: number}[] | [DateTime, DateTime][]>[] = [];
    // let axes: {[key: string]: ScaleOptionsByType<"time">} = {};
    let axes: any = {};

    props.metrics.forEach(metric => {
        switch (metric) {
            case "avgSleepHr":
                datasets.push(...trendDataset(
                    metric,
                    "Avg sleeping hr",
                    props.trends.avg_sleep_hr.value,
                    props.trends.avg_sleep_hr.best_fit,
                    "#7ace4c",
                    props.showTrendlines
                ));
                axes["y_avgSleepHr"] = {
                    beginAtZero: false,
                    ticks: { color: "#7ace4c" }
                };
                break;
            case "totalSteps":
                datasets.push(...trendDataset(
                    metric,
                    "Total steps",
                    props.trends.total_steps.value,
                    props.trends.total_steps.best_fit,
                    "#00D1C5",
                    props.showTrendlines
                ));
                axes["y_totalSteps"] = {
                    beginAtZero: false,
                    ticks: { color: "#00D1C5" }
                };
                break;
            case "sleepDuration":
                datasets.push(...trendDataset(
                    metric,
                    "Sleep duration",
                    props.trends.sleep_duration.value,
                    props.trends.sleep_duration.best_fit,
                    "#4C80CE",
                    props.showTrendlines
                ));
                axes["y_sleepDuration"] = {
                    beginAtZero: false,
                    ticks: { color: "#4C80CE" }
                };
                break;
            case "sleepInstability":
                datasets.push(...trendDataset(
                    metric,
                    "Sleep instability",
                    props.trends.sleep_instability.value,
                    props.trends.sleep_instability.best_fit,
                    "#C22A9F",
                    props.showTrendlines
                ));
                axes["y_sleepInstability"] = {
                    beginAtZero: false,
                    ticks: { color: "#C22A9F" }
                };
                break;
            case "scoresD":
                datasets.push(...trendDataset(
                    "scores",
                    "Depression score",
                    props.trends.depression_scores.value,
                    props.trends.depression_scores.best_fit,
                    "#E9922C",
                    props.showTrendlines
                ));
                axes["y_scores"] = scoresScale;
                break;
            case "scoresA":
                datasets.push(...trendDataset(
                    "scores",
                    "Anxiety score",
                    props.trends.anxiety_scores.value,
                    props.trends.anxiety_scores.best_fit,
                    "#F5DD03",
                    props.showTrendlines
                ));
                axes["y_scores"] = scoresScale;
                break;
            case "dassD":
                if (!props.trends.dass21s) break;
                datasets.push(dassDataset(
                    "Depression (DASS-21)",
                    "#A12CE9",
                    props.trends.dass21s.depression,
                    props.trends.dass21s.timestamp
                ));
                axes["y_dass"] = dassScale;
                break;
            case "dassA":
                if (!props.trends.dass21s) break;
                datasets.push(dassDataset(
                    "Anxiety (DASS-21)",
                    "#F423BA",
                    props.trends.dass21s!.anxiety,
                    props.trends.dass21s!.timestamp
                ));
                axes["y_dass"] = dassScale;
                break;
            case "dassS":
                if (!props.trends.dass21s) break;
                datasets.push(dassDataset(
                    "Stress (DASS-21)",
                    "#E43A3A",
                    props.trends.dass21s!.stress,
                    props.trends.dass21s!.timestamp
                ));
                axes["y_dass"] = dassScale;
                break;
            case "treatments":
                axes["y_treatment"] = { display: false };
                const courses = props.trends.treatment_courses;
                if (courses.length > 0) {
                    for (let course of courses) {
                        // backwards compatibiliy code during transition to new tms start, end fields
                        let _start = "medication" in course ? course["start"] : (course["began"] || course["start"]);
                        if (!_start) continue;
                        let _end = "medication" in course ? course["end"] : (course["finished"] || course["end"]);
                        datasets.push({
                            type: "bar",
                            data: [[
                                DateTime.fromISO(_start),
                                _end ? DateTime.fromISO(_end) : DateTime.fromJSDate(props.dateRange[1])
                            ]] as [DateTime, DateTime][],
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            yAxisID: "y_treatment",
                            indexAxis: "y",
                            label: "medication" in course ? course.medication.name : `TMS course ${formatDate(_start!)} - ${_end ? formatDate(_end!) : "ongoing"}`,
                            datalabels: {
                                display: true,
                                anchor: "start",
                                color: "#fff",
                                align: "end",
                                clamp: true,
                                formatter: (value, context) => {
                                    return "medication" in course ? course.medication.name : `TMS course ${formatDate(_start!)} - ${_end ? formatDate(_end!) : "ongoing"}`
                                }
                            },
                        } as ChartDataset<"bar", [DateTime, DateTime][]>)
                    }
                }
                break;
            default:
                break;
        }
    });

    const options: ChartOptions = {
        responsive: true,
        animation: false as const,
        plugins: {
            legend: false as const,
            tooltip: {
                enabled: true,
                intersect: false,
                mode: "index"
            },
            datalabels: {
                display: false
            }
        },
        scales: {
            x: {
                type: "time" as const,
                time: { minUnit: "day" },
                max: props.dateRange[1],
                min: props.dateRange[0]
            },
            y_generic: {},
            ...axes
        }
    };

    const data: ChartData<"line" | "bar", number[] | {x: DateTime, y: number}[] | [DateTime, DateTime][], DateTime> = {
        labels: props.trends.dates.map(t => DateTime.fromISO(t)),
        datasets: datasets
    };

    const drawline = (chart: any, val: number) => {
        let yAxis = chart.scales.y_generic;
        let xAxis = chart.scales.x;
        let ctx = chart.ctx;
        ctx.save();
        ctx.beginPath();
        let x = xAxis.getPixelForValue(val);
        ctx.moveTo(x, yAxis.top);
        ctx.lineTo(x, yAxis.bottom);
        ctx.lineWidth = 2;
        ctx.strokeStyle = 'rgba(0, 0, 255)';
        ctx.stroke();
        ctx.restore(); 
    };

    const mouseLinePlugin: Plugin = {
        id: "mouseLine",
        afterDraw: (chart) => {
            if (chart.tooltip?.dataPoints?.length) {
                let xVal = chart.tooltip.dataPoints[0].parsed.x;
                drawline(chart, xVal);
                // console.log(xVal, new Date(xVal));
                if(props.onHover) props.onHover((new Date(xVal)).toISOString().split("T")[0])
            }
        }
    }

    return <Chart
        type="line"
        data={data}
        options={options}
        plugins={[mouseLinePlugin]} />;
}
