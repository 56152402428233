import React, { createContext, useContext, useEffect, useRef, useState } from "react";
import { User } from "../types";
import { DOMAIN } from "../constants";
import axios from "axios";
import { CognitoRefreshToken, CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import { AppContext, AppState } from "./AppContext";
import { logError, logInfo } from "../utils";
import { NavigateFunction } from "react-router-dom";

enum AuthInitState {
	none,
	loading,
	done
}

type AuthState = {
	authenticated: boolean,
	user: User | null,
	authSource?: AuthSource,
	cognitoSession?: CognitoUserSession
};

export enum AuthSource {
	django,
	cognito
}

type AuthContextType = {
	initState: AuthInitState,
	authState: AuthState,
	setAuthState: (authState: AuthState) => void,
	getUserId: () => string | null,

	accessToken: React.MutableRefObject<string | null>,
	refreshToken: React.MutableRefObject<string | null>,
	// authenticated: boolean,
	// userId: string | number | null,

	logout: () => Promise<void>,
	doAuthRefresh: () => Promise<unknown>,
	// login: (email: string, password: string) => Promise<void>
};

const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider = ({ children }: React.PropsWithChildren) => {

	const { userPool, initState: appInitState } = useContext(AppContext);
	const [initState, setInitState] = useState(AuthInitState.none);
	const [refreshing, setRefreshing] = useState(false);
	const accessToken = useRef<string | null>(null);
	const refreshToken = useRef<string | null>(null);

	const [authState, setAuthState] = useState<AuthState>({
		authenticated: false,
		user: null
	});

	const wrapSetAuthState = (nextAuthState: AuthState) => {
		if (nextAuthState.cognitoSession) {
			accessToken.current = nextAuthState.cognitoSession.getAccessToken().getJwtToken()
			refreshToken.current = nextAuthState.cognitoSession.getRefreshToken().getToken();
		}
		return setAuthState(nextAuthState);
	}

	const logout: () => Promise<void> = async () => {
		logInfo("Logging out");
		setAuthState({
			authenticated: false,
			user: null
		});
		accessToken.current = null;
		refreshToken.current = null;

		localStorage.removeItem("access");
		localStorage.removeItem("refresh");
		localStorage.removeItem("user");

		const user = userPool?.getCurrentUser();

		if (user) {
			user.getSession(() => {
				user.globalSignOut({
					onSuccess(msg) {
						logInfo(msg);
					},
					onFailure(err) {
						logError(err);
					},
				});
			})
		}
	};

	function djangoRefresh() {
		const data = {
			refresh: refreshToken.current,
		};

		const options = {
			method: "POST",
			data,
			url: DOMAIN + "api/token/refresh/",
		};

		return axios(options)
			.then(async tokenRefreshResponse => {
				const { access, refresh, user } = tokenRefreshResponse.data;

				// failedRequest.response.config.headers.Authorization = "Bearer " + access;

				setAuthState({
					...authState,
					user: user,
					authSource: AuthSource.django
				});
				accessToken.current = access;
				refreshToken.current = refresh;

				localStorage.setItem("access", access);
				localStorage.setItem("refresh", refresh);
				localStorage.setItem("user", JSON.stringify(user));

				return Promise.resolve();
			})
			.catch(() => {
				logout();
			});
	}

	function cognitoDjangoHandshake(session: CognitoUserSession) {
		const options = {
			method: "POST",
			data: {
				AccessToken: session.getAccessToken().getJwtToken()
			},
			url: DOMAIN + "accounts/cognito-handshake"
		}
		return axios(options)
			.then(res => {
				const user = res.data.user;
				setAuthState({
					user,
					authSource: AuthSource.cognito,
					authenticated: true,
					cognitoSession: session
				})

				// For backwards compat
				accessToken.current = session.getAccessToken().getJwtToken()
				refreshToken.current = session.getRefreshToken().getToken();

				localStorage.setItem("access", accessToken.current);
				localStorage.setItem("refresh", refreshToken.current);
				localStorage.setItem("user", JSON.stringify(user));
			})
			.catch(err => {
				logError(err);
				logout();
			})
	}

	function cognitoRefresh(session?: CognitoUserSession) {
		const user = userPool?.getCurrentUser()
		if (user && session) {
			return new Promise((resolve, reject) => user.refreshSession(session.getRefreshToken(), (err, result) => {
				if (err) {
					return reject(err);
				}

				if (result) {
					return cognitoDjangoHandshake(result);
				}
			}))
		}
		return Promise.reject("No valid cognito session to refresh")
	}

	const doAuthRefresh = () => {

		if (refreshing) {
			// Prevent infinite recursion on 401 failures
			logError("Already refreshing a token")
			return Promise.reject("Already refreshing a token");
		}

		setRefreshing(true);

		if (authState.authSource == AuthSource.cognito) {
			return cognitoRefresh(authState.cognitoSession)
				.finally(() => {
					setRefreshing(false)
				})
		} else {
			return djangoRefresh()
				.finally(() => {
					setRefreshing(false)
				})
		}
	};

	const getUserId: () => string | null = () => {
		return authState.user ? authState.user?.id.toString() : null;
	};

	useEffect(() => {

		if (appInitState == AppState.initialised && initState == AuthInitState.none) {

			if (!userPool) {

				const localRefresh = localStorage.getItem("refresh");

				if (localRefresh) {
					djangoRefresh()
						.finally(() => {
							setInitState(AuthInitState.done);
						});
				} else {
					setInitState(AuthInitState.done);
				}

			} else {
				const user = userPool.getCurrentUser()

				if (user) {
					setInitState(AuthInitState.loading);
					user.getSession((err: Error | null, gotSession: CognitoUserSession | null) => {

						if (err) {
							logError(err);
							user.signOut();
							setInitState(AuthInitState.done);
						}
						else if (gotSession) {
							cognitoRefresh(gotSession)
								.finally(() => {
									setInitState(AuthInitState.done);
								});
						} else {
							logError("Invalid response from cognito")
							setInitState(AuthInitState.done);
						}
					}
					)
				}
			}
		}

	}, [userPool, initState])

	return (
		<AuthContext.Provider
			value={{
				accessToken,
				refreshToken,
				authState,
				initState,
				doAuthRefresh,
				setAuthState: wrapSetAuthState,
				getUserId,
				logout
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };