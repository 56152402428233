import { CognitoUserPool, CognitoUserSession, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js"
import { createContext, useContext, useEffect, useState, Dispatch, SetStateAction } from "react";
import { AxiosContext } from "./AxiosContext";
import { AuthContext } from "./AuthContext";
import { AppContextType } from "./AppContext";

export enum CognitoSessionState {
    none,
    fetching,
    found,
    error
}

export type CognitoContextType = {
    session?: CognitoUserSession;
    setSession?: Dispatch<SetStateAction<CognitoUserSession | undefined>>;
    user?: CognitoUser;
    setUser?: Dispatch<SetStateAction<CognitoUser | undefined>>;
    userAttributes?: CognitoUserAttribute[];
}

export type AccountStatusResponse = {
    cognito_linked: boolean; // True iff cognito account and django account exist for this email and are linked
    cognito_exists: boolean; // True iff cognito account exists for this email
    exists: boolean; // True iff the django account exists for this email
}

export const CognitoContext = createContext<CognitoContextType>({ });

export function appRedirectUri(cognitoContext: CognitoContextType, appContext: AppContextType) {
    const session = cognitoContext.session;
    if (!session || !appContext.appConfig || !appContext.appConfig.cognito_user_pool_client_id) {
        return null;
    }
    const idToken = encodeURIComponent(session.getIdToken().getJwtToken())
    const accessToken = encodeURIComponent(session.getAccessToken().getJwtToken())
    const refreshToken = encodeURIComponent(session.getRefreshToken().getToken())
    const clientId = encodeURIComponent(appContext.appConfig.cognito_user_pool_client_id);
    const userPool = encodeURIComponent(appContext.appConfig.cognito_user_pool_id);
    return `${appContext.appConfig.app_redirect_uri}?idToken=${idToken}&accessToken=${accessToken}&refreshToken=${refreshToken}&clientId=${clientId}&userPool=${userPool}`;
}

export const CognitoProvider = ({ children }: React.PropsWithChildren) => {

    const auth = useContext(AuthContext);
    const [user, setUser] = useState<CognitoUser>();
    const [session, setSession] = useState<CognitoUserSession>();

    return <CognitoContext.Provider
        value={{
            session,
            setSession,
            user,
            setUser
        }}
    >
        {children}
    </CognitoContext.Provider>
}
